//This file is included in the head of the index.html file and will dictate which js includes are necessary based on environment
// for example, the different js includes will have the New Relic scripts that are relevant to the specific environment
const fs = require('fs');

if (process.env.NODE_ENV === 'UAT')
{ //import UAT.js file
  require('./UAT.js')
}
else if (process.env.NODE_ENV === 'DEV-QA')
{ 
  require('./DEV-QA.js')
}
else if (process.env.NODE_ENV === 'CLOUD')
{ 
  require('./CLOUD.js')
}
else if (process.env.NODE_ENV === 'LOCAL-DEV')
{
  require('./LOCAL.js')
}
//currently there is no default includes file

